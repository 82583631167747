@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;700&display=swap");

body {
  font-family: "Roboto", sans-serif;
  @apply bg-brand;
}

.login-form {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  position: fixed;
  div {
    margin-bottom: 0rem;
  }
  .statusLink{
    font-size:13px;
  }
}

textarea {
  height: 200px;
}

button:disabled {
  opacity: 0.75;
  filter: grayscale(1);
  cursor: not-allowed;
}

.tooltip {
  @apply invisible absolute p-2 bg-info-dark top-full rounded-md whitespace-nowrap text-white;
  &.tooltip-right {
    @apply right-0;
    &::before {
      @apply right-2;
    }
  }
  &.tooltip-left {
    @apply left-0;
    &::before {
      @apply left-2;
    }
  }
  &::before {
    @apply absolute bottom-full text-info-dark;
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent currentColor transparent;
  }
}

.has-tooltip {
  @apply relative;
}

.has-tooltip:hover:not(:disabled) .tooltip {
  @apply visible z-50;
}

span.tag {
  @apply p-1 pr-3 pl-3 block text-xs font-bold  text-white rounded-2xl text-center min-w-max whitespace-nowrap;
  &.tag-success {
    @apply bg-success;
  }
  &.tag-fail {
    @apply bg-danger;
  }
}

a.active {
  @apply bg-cta;
}

.ql-editor {
  min-height: 200px;
}

.markdown {
  h1 {
    @apply text-2xl;
  }
  h2 {
    @apply text-xl;
  }
  h3 {
    @apply text-lg;
  }
}

.data-table {
    .statusLink {
        font-size: 13px;
        color:#fff;
        padding:1rem;
        text-align: center;
        display: block;
      }
  table:not(.infotable){
  tr {
    @apply bg-gray-100;
    &:nth-child(odd) {
      @apply bg-gray-200;
    }
  }
  td,
  th {
    @apply p-2 whitespace-nowrap;
    text-overflow: ellipsis;
    max-width: 250px;
    overflow: hidden;
    &.small {
      max-width: 120px;
    }
  }
  th {
    @apply bg-brand-dark text-white;
  }
}
}

input:read-only {
  opacity: 0.5;
}

.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  border: 3px solid;
  border-color: #fff #fff transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.loader::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 3px solid;
  border-color: transparent #fd7d4d #fd7d4d;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  animation: rotationBack 0.5s linear infinite;
  transform-origin: center center;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

.gradient-bg {
  background: #fc466b; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #fc466b,
    #3f5efb
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #fc466b,
    #3f5efb
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}


table.infotable {
  line-height: 1.5;
  th,td{
    padding: .5rem 0;
  }
  th{
    text-transform: uppercase;
    padding-right:.5rem!important;
    vertical-align: text-top;
    text-align: left;
  }
}

.policypage{
  line-height: 1.5;
  p{
    margin-bottom: 1rem;
  }
  ol{
    list-style:lower-alpha inside;
    margin-bottom: 1rem;
    li{
      padding:.2rem 0;
    }
  }
}